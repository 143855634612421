<template>
  <div class="box">
    <div class="infos">
      <div class="titles">
        <div class="left">
          <span v-for="(item, index) in title" :key="indxe" :class="tabsIndex == index ? 'active' : ''" @click="loadList(index)">{{ item }}</span>
        </div>
        <div class="right">
          <div class="button" @click="$refs.formDialog.formVisible = true">
            <span>+ 新增代理商</span>
          </div>
          <div class="search">
            <i class="el-icon-search"></i>
            <input class="el-input__inner" @change="loadList()" v-model="search" type="text" autocomplete="off" placeholder="输入账号搜索" />
          </div>
		  <div class="search">
		    <i class="el-icon-search"></i>
		    <input class="el-input__inner" @change="loadList()" v-model="phoneValue" type="text" autocomplete="off" placeholder="输入手机号" />
		  </div>
        </div>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
        <el-table-column prop="username" label="登录账号" align="center"></el-table-column>
        <el-table-column prop="phone" label="电话" align="center">
          <el-tooltip slot-scope="scope" :content="scope.row.phone" placement="top">
            <span class="number">{{ scope.row.phone }}</span>
          </el-tooltip>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
        <el-table-column label="业务类型" align="center" width="240px">
          <div slot-scope="scope" class="images">
            <img style="width: 20px; height: 20px" v-for="item in scope.row.businessTypes" :src="item" alt="" />
          </div>
        </el-table-column>
        <el-table-column label="开通时间" align="center">
          <span slot-scope="scope">{{ scope.row.register_date | date('Y-m-d H:i') }}</span>
        </el-table-column>
        <el-table-column label="到期时间" align="center">
          <span v-if="scope.row.endTime === true" prop="endTime" slot-scope="scope">永久</span>
          <span v-else prop="endTime" slot-scope="scope">{{ scope.row.endTime | date('Y-m-d H:i') }}</span>
        </el-table-column>
        <el-table-column prop="quota" label="额度" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <span @click="changeStatus(scope.row, scope.$index)" :class="scope.row.status == 0 ? 'yes' : 'yes no'" slot-scope="scope">{{ scope.row.status == 0 ? '正常' : '禁用' }}</span>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <div class="button">
              <span @click="edit(scope.row)">编辑</span>
              <span @click="reset(scope.row)">重置密码</span>
              <span @click="recharge(scope.row)">充值</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <el-pagination @size-change="numChange" @current-change="pagination" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="pageLimit" :current-page="page" :pager-count="4" layout="total, sizes, prev, pager, next, jumper" :total="totalSize"></el-pagination>
      </div>
    </div>
    <!-- 新增、编辑 -->
    <agents-manage ref="formDialog" :list="list" @refreshData="loadList"></agents-manage>
    <!-- 充值 recharge -->
    <recharge-manage ref="rechargeDialog" :list="rechargeList" @refreshData="loadList"></recharge-manage>
  </div>
</template>

<script>
import agentsManage from './agents-manage'
import rechargeManage from './recharge-manage'
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  components: {
    agentsManage,
    rechargeManage
  },
  data() {
    return {
      search: '',
      phoneValue: '',
      page: 1,
      pageLimit: 20,
      totalSize: 0,
      tabsIndex: 0,
      rechargeList: {},
      list: [],
      title: ['全部', '即将到期', '已过期', '停用']
    }
  },
  //监听函数
  watch: {
    '$route' () {
	  // 跳转恢复初始值
	  this.page = 1;
	  this.pageLimit = 20;
	  this.search = '';
	  this.phoneValue = '';
      this.loadList(0);//我的初始化方法
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList(index) {
      if (index || index == 0) {
        this.tabsIndex = index
      }
      const { page, pageLimit } = this
      const send = {
        offset: (page - 1) * pageLimit,
        limit: pageLimit,
        search: this.search,
        phoneValue: this.phoneValue,
        type: 'agent',
        index: this.tabsIndex
      }
	  const value = this.$route.query.index
	  if(value == 2) send.status = 0
	  if(value == 3) send.status = 1
      const res = await http.GET('/jw-admin', {
        module: 'admin',
        operation: 'getAgentLists',
        ...send
      })
      this.totalSize = res.affectedDocs
      this.list = res.data
    },
    // 修改状态
    async changeStatus(e, index) {
      if (e.status) {
        e.status = 0
      } else {
        e.status = 1
      }
      const res = await http.GET('/jw-admin', {
        module: 'admin',
        operation: 'setCategoryStatus',
        id: e._id,
        status: e.status
      })
      if (res) {
        this.$message.success('修改成功！')
      } else {
        this.$message.error('修改失败！')
      }
    },
    // 编辑
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.formDialog.formVisible = true
    },
    // 重置
    reset(data) {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      // 默认密码
      data.password = '123456'
      this.$confirm(`确认是否重置密码为123456吗？`, '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          http
            .POST('/jw-admin', {
              module: 'admin',
              operation: 'updateAgentInfo',
              data
            })
            .then(response => {
              this.$message.success(response.msg)
              this.loadList()
            })
        })
        .catch(() => {})
    },
    // 充值
    recharge(item) {
      this.$refs.rechargeDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.rechargeDialog.rechargeVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead {
  th.el-table__cell {
    background: #ecf1f6;
  }
  color: #20303f !important;
}
::v-deep tbody {
  tr:nth-child(2n) {
    background: #fafbfc;
  }
}
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f4f6f8;
  .infos {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    .titles {
      display: flex;
      justify-items: center;
      margin-bottom: 20px;
      .left {
        width: calc(100% - 200px);
        span {
          display: inline-block;
          height: 36px;
          padding: 0 22px;
          margin-right: 10px;
          line-height: 36px;
          color: #20303f;
          text-align: center;
          cursor: pointer;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
        }
        .active {
          color: #fefefe;
          background: #6954f0;
        }
      }
      .right {
        display: flex;
        .button {
          width: max-content;
          height: 36px;
          margin-right: 14px;
          border-radius: 4px;
          padding: 0 14px;
          background-color: #ff6a6b;
          cursor: pointer;
          span {
            font-size: 14px;
            line-height: 36px;
            color: #fff;
          }
          &:hover {
            opacity: 0.9;
          }
        }
        .search {
          width: 160px;
          height: 36px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding: 1px 11px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          i {
            display: inline-flex;
            white-space: nowrap;
            flex-shrink: 0;
            flex-wrap: nowrap;
            text-align: center;
            color: #a8abb2;
            transition: all 0.3;
            pointer-events: none;
            margin-right: 8px;
          }
		  &:last-child{
			margin-left: 6px;
		  }
        }
        .el-input__inner {
          width: 100%;
          height: 36px;
          flex-grow: 1;
          color: #606266;
          padding: 0;
          border: none;
        }
      }
    }
    .el-table {
      border-radius: 5px;
      box-shadow: 0 4px 14px 1px rgb(54 124 253 / 8%);
      .el-table__header-wrapper {
        border-radius: 5px 5px 0 0;
      }
      font-size: 14px;
      font-weight: 400;
      color: #6b7c8c;
      .images {
        display: flex;
        align-items: center;
        justify-content: center;
        > img:not(:nth-child(1)) {
          margin-left: 10px;
        }
      }
      .yes {
        display: inline-block;
        height: 32px;
        padding: 0 14px;
        font-size: 13px;
        line-height: 32px;
        cursor: pointer;
        border-radius: 3px;
        color: #59ab31;
        background: #f0f9eb;
        border: 1px solid #e1f3d8;
      }
      .no {
        color: #f56c6c;
        background: #fef0f0;
        border: 1px solid #fde2e2;
      }
      .button {
        display: flex;
        justify-content: center;
        > span {
          margin-right: 14px;
          color: #6954f0;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .number {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}
</style>

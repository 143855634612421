<template>
  <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="rechargeVisible">
    <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left:50px;">
      <el-form-item label="初始金额" prop="quota">
        <el-input v-model="formData.quota" :disabled="true">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
	  <el-form-item label="账号状态" prop="recharge">
	    <el-radio v-model="formData.recharge" :label="true">增加</el-radio>
	    <el-radio v-model="formData.recharge" :label="false">减少</el-radio>
	  </el-form-item>
	  <el-form-item label="充值金额" prop="money">
	    <el-input v-model="formData.money" onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))">
	      <template slot="append">元</template>
	    </el-input>
	  </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="padding-left: 140px">
      <el-button size="medium" class="confirm-btn" @click="rechargeVisible = false">取消</el-button>
      <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud';

export default {
  data() {
    return {
      rechargeVisible: false, //表单显示状态
      formData: {}, //表单数据
	  money:'',
	  recharge:'',
      rules: {
		recharge: [{ required: true, message: '请选择充值类型', trigger: 'change' }],
		money: [{ required: true, message: '请输入充值金额', trigger: 'change' }]
      },
    };
  },
  props: {
    roleList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    formTitle() {
      return '充值';
    },
  },
  watch: {
    rechargeVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {};
        }
      } else {
        this.$refs.dataForm.resetFields();
        this.formData = {};
      }
    },
  },
  methods: {
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return;
        }
		let value = Number(this.formData.quota)
		if(this.formData.recharge){
			value += Number(this.formData.money)
		}else{
			value -= Number(this.formData.money)
			if(value<0){
			  this.$message.error('输入减少金额过大');
			  return;
			}
		}
		const response = await http.POST('/jw-admin', {
		  module: 'admin',
		  operation: 'agentRecharge',
		  id: this.formData._id,
		  money: value
		});
		if (response.status === 1) {
		  this.$message.success(response.msg);
		  this.$emit('refreshData');
		  this.rechargeVisible = false;
		} else {
		  this.$message.error(response.msg);
		}
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* 加宽行 */
.form-item-widen {
  width: 650px;
}
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
.tip {
  font-size: 11px;
  color: #ff4443;
}
</style>

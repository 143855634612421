<template>
  <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
    <div class="box">
      <div class="left">
        <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left">
          <div class="l-top">
            <el-form-item label="开通业务类型: " prop="businessTypes">
              <el-checkbox-group v-model="formData.businessTypes">
                <el-checkbox v-for="item in typesList" :label="item.img" name="type">
                  <img :src="item.img" alt="" />
                  <span>{{ item.text }}</span>
                  <div class="corner-icon"><i class="el-icon-check"></i></div>
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="line"></div>
          <div class="l-cont">
            <el-form-item label="登录账号" prop="username">
              <el-input v-model="formData.username" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item v-if="showPassword" label="登录密码" prop="password">
              <el-input v-model="formData.password" show-password placeholder="请输入密码" />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formData.name" placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="formData.phone" placeholder="请输入联系电话" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="formData.email" placeholder="请输入邮箱" />
            </el-form-item>
            <el-form-item class="time" label="账号到期时间" prop="endTime">
              <el-checkbox v-model="formData.endTime">永久有效</el-checkbox>
              <el-date-picker :disabled="formData.endTime == true ? true : false" v-model="endTime" type="date" value-format="timestamp" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="初始金额" prop="quota">
              <el-input v-model="formData.quota" type="number" placeholder="请输入金额" onkeyup="this.value=this.value.match(/\d+\.?\d{0,9}/);this.dispatchEvent(new Event('input'))">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="账号状态">
              <el-radio v-model="formData.status" :label="0">开启</el-radio>
              <el-radio v-model="formData.status" :label="1">关闭</el-radio>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 10px">
          <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
          <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
        </div>
      </div>
      <div class="right">
        <Imgpond class="img" v-model="formData.icon" :count="1" @success="imgSuccess" />
        <span class="title">头像</span>
        <span class="tip">（建议上传正方形图片）</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  data() {
    return {
      showPassword: true,
      formVisible: false, //表单显示状态
      formData: {
        businessTypes: [],
        resource: true,
        quota: 0
      }, //表单数据
      endTime: '',
      rules: {
        businessTypes: [{ type: 'array', required: true, message: '请选择开通业务类型', trigger: 'change' }],
        username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      typesList: [
        {
          img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922590057ce.png',
          text: 'PC网站'
        },
        {
          img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922604087ce.png',
          text: 'H5网站'
        },

        {
          img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922583841ce.png',
          text: '微信小程序'
        },
        {
          img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922578578ce.png',
          text: 'APP'
        }
        // {
        //   img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922594944ce.png',
        //   text: 'QQ小程序'
        // },

        // {
        //   img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922617053ce.png',
        //   text: 'H5应用'
        // },
        // {
        //   img: 'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922624858ce.png',
        //   text: '字节跳动小程序'
        // }
      ]
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改代理商' : '新增代理商'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            businessTypes: [],
            status: 0,
            endTime: true,
            quota: 0
          }
          this.showPassword = true
        } else {
          this.showPassword = false
          this.endTime = this.formData.endTime
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {
          businessTypes: []
        }
      }
    }
  },
  methods: {
    submit() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      if (this.formData.endTime !== true) this.formData.endTime = this.endTime

      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const operation = this.formData._id ? 'updateUserInfo' : 'addAgentUser'
        const data = this.formData
        const response = await http.POST('/jw-admin', {
          module: 'admin',
          operation: operation,
          data
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    },
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64)
      if (result) {
        console.log(result.url)
        this.formData.icon = result.url
      } else {
        this.$message.error('图片失败')
        this.formData.icon = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 820px;
    max-width: 820px;
  }
  ::v-deep .el-dialog__header {
    background: #eef1f6;
    border-radius: 10px 10px 0 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep el-form-item__label {
    line-height: 30px;
  }
  .box {
    display: flex;
    .left {
      width: calc(100% - 190px);
      padding: 30px 15px;
      .l-top {
        ::v-deep .el-checkbox__inner {
          display: none;
        }
        ::v-deep .el-form-item {
          display: flex;
          flex-direction: column;
          .el-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-checkbox {
              width: 110px;
              height: 70px;
              margin: 0 10px 10px 0;
              display: flex;
              background: #fafbfc;
              border-radius: 4px;
              .el-checkbox__label {
                flex: 1;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-left: 0;
                > img {
                  width: 20px;
                  height: 20px;
                }
                span {
                  margin-top: 10px;
                  font-size: 14px;
                  color: #20303f;
                }
                .corner-icon {
                  display: none;
                }
              }
            }
            .is-checked {
              background: #edeafd;
              .el-checkbox__label {
                .corner-icon {
                  display: flex;
                  position: absolute;
                  top: 0;
                  right: 0;
                  z-index: 3;
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  background: #6954f0;
                  border-radius: 0 0 0 20px;
                  i {
                    font-size: 14px;
                    color: #fff;
                    margin: 2px 0 0 4px;
                  }
                }
              }
            }
          }
        }
      }
      .line {
        border: 1px dashed #ebeef5;
        margin-bottom: 15px;
      }
      .l-cont {
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
          max-width: 50%;
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          .el-input {
            width: 260px;
            height: 36px;
            line-height: 36px;
          }
        }
        .time {
          .el-form-item__content {
            position: relative;
            display: flex;
            .el-checkbox {
              position: absolute;
              right: 50px;
              top: -40px;
            }
            .is-checked {
              ::v-deep .el-checkbox__inner {
                background: #6954f0;
                border-color: #6954f0;
              }
              ::v-deep .el-checkbox__label {
                color: #20303f;
              }
            }
          }
        }
      }
    }
    .right {
      width: 190px;
      padding-top: 40px;
      text-align: center;
      background: #f7f8fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 98px;
        height: 98px;
        line-height: 98px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }
      ::v-deep .el-upload-list__item {
        width: 98px !important;
        height: 98px !important;
        border: none;
        border-radius: 50%;
        margin: 0;
        > img {
          width: 98px;
          height: 98px;
        }
      }
      ::v-deep .el-upload--picture-card {
        width: 98px !important;
        height: 98px !important;
        border: none;
        border-radius: 50%;
        margin: 0;
        background: url('https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673942464190ce.png') 100% 100% no-repeat;
        background-size: cover;
        i {
          display: none;
        }
      }
      .title {
        margin-top: 15px;
        font-size: 14px;
        color: #20303f;
      }
      .tip {
        margin-top: 10px;
        font-size: 13px;
        color: #adbac6;
      }
    }
  }
}
.tip {
  font-size: 11px;
  color: #ff4443;
}
</style>
